@import './animation.css';

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Digitalt';
  src: local('Digitalt'), url('../fonts/Digitalt/Digitalt.ttf') format('truetype');
}
@font-face {
  font-family: 'iCiel-Cadena';
  src: local('iCiel-Cadena'), url('../fonts/iCiel-Cadena/iCiel-Cadena.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Slab';
  font-weight: normal;
  src: local('Roboto-Slab'), url('../fonts/Roboto-Slab/RobotoSlab-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Slab';
  font-weight: bold;
  src: local('Roboto-Slab'), url('../fonts/Roboto-Slab/RobotoSlab-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Monument';
  src: local('Monument'), url('../fonts/MonumentExtended/MonumentExtended-Regular.otf') format('OpenType');
}

@font-face {
  font-family: 'Jua';
  src: local('Jua'), url('../fonts/Jua/Jua-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Unbounded';
  src: local('Unbounded'), url('../fonts/Unbounded/Unbounded-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Lora/Lora-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Lora/Lora-Medium.ttf') format('truetype');
  font-weight: medium;
}

@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Lora/Lora-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Lora/Lora-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-display: swap;
  src: url('../fonts/Lora/Lora-Italic.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-display: swap;
  src: url('../fonts/Lora/Lora-MediumItalic.ttf') format('truetype');
  font-weight: medium;
}

@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-display: swap;
  src: url('../fonts/Lora/Lora-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-display: swap;
  src: url('../fonts/Lora/Lora-BoldItalic.ttf') format('truetype');
  font-weight: bold;
}
